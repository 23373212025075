import Carousel from 'react-bootstrap/Carousel';
import useFetch from './useFetch';

function Slider() {
    const { data } = useFetch('https://www.nvnageldb.de/public/api/sliders')
    /* const { data } = useFetch('localhost:3001/api/v1/sliders') */

    return (
        <div className='container-lg'>
            <div className='row'>
                <div className='col'>
                    <Carousel>
                        {data.map((slide, index) => {
                            return (
                                <Carousel.Item key={index}>
                                    <img className='d-block w-100' src={slide.image_path} style={{ height: '80vh', objectFit: 'cover', backgroundSize: 'cover' }} alt='test' />
                                    {/* <img className='d-block w-100' src={`image/slider/${slide.image}`} style={{ height: '80vh', objectFit: 'cover', backgroundSize: 'cover' }} alt='test' /> */}
                                    <Carousel.Caption>
                                        <h3>{slide.text1}</h3>
                                        <h3>{slide.text2}</h3>
                                        <h3>{slide.text3}</h3>
                                    </Carousel.Caption>
                                </Carousel.Item>
                            )
                        })}

                    </Carousel>
                </div>
            </div>
        </div>
    );
}

export default Slider;