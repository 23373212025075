import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import './Navbar.css'
import nv from '../assets/nv12.png'
import { FaBars } from "react-icons/fa";
import { FiPhone } from "react-icons/fi";



import { FaCartArrowDown } from "react-icons/fa";



const Navbar = () => {
    const [menuOpen, setMenuOpen] = useState(false)



    return (
        <nav>
            <div className='title'>
                <Link className='logo' to='/'><img src={nv} style={{ height: '35px', width: '35px', borderRadius: '50%' }} /> Nagelstudio</Link>
                <a className='text-white ms-3 me-2' href='tel:01629609309' style={{ textDecoration: 'none' }}><FiPhone />:0162 9609309</a>
                <Link className='text-white' style={{ fontSize: '1.5rem' }} to='/cart'><FaCartArrowDown /></Link>
            </div>
            <Link className='mobile' to='/' onClick={() => setMenuOpen(!menuOpen)} ><FaBars /></Link>
            <ul className={menuOpen ? 'open' : ''}>
                <li><NavLink to='/shellack' onClick={() => setMenuOpen(!menuOpen)}>Shellack</NavLink></li>
                <li><NavLink to='/gel' onClick={() => setMenuOpen(!menuOpen)}>Gel</NavLink></li>
                <li><NavLink to='/pflege' onClick={() => setMenuOpen(!menuOpen)}>Pflege</NavLink></li>
                <li><NavLink to='/extra' onClick={() => setMenuOpen(!menuOpen)}>Extra</NavLink></li>
                <li><NavLink to='/kontact' onClick={() => setMenuOpen(!menuOpen)}>Kontact</NavLink></li>
                <li><NavLink to='/impressum' onClick={() => setMenuOpen(!menuOpen)}>Impressum</NavLink></li>
            </ul>

        </nav>
    );
}

export default Navbar;

