import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { Routes, Route } from 'react-router-dom'
import Navbar from './components/Navbar'
import Footer from './components/Footer'
import { Gel, Shellack, Extra, Kontact, Pflege, Impressum, Home, Cart } from './components/pages';
import { CartProvider } from 'react-use-cart';

function App() {
  return (
    <div className="App">
      <CartProvider>
        <Navbar />
        <Routes  >
          <Route path='/gel' element={<Gel />} />
          <Route path='/shellack' element={<Shellack />} />
          <Route path='/extra' element={<Extra />} />
          <Route path='/kontact' element={<Kontact />} />
          <Route path='/pflege' element={<Pflege />} />
          <Route path='/impressum' element={<Impressum />} />
          <Route path='/' element={<Home />} />
          <Route path='/cart' element={<Cart />} />
        </Routes>
        <Footer />
      </CartProvider>
    </div>
  );
}

export default App;
