import React from 'react';

export const Impressum = () => {
    return (
        <div className='container'>
            <div className='row'>



                <h2>Demo Website. Demo Website</h2>
                <p>Hier ist mein Demo website</p>




            </div>
            <p>Email: vtp5330@gmail.com</p>
        </div>
    );
};

