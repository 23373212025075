import React from 'react';

export const Kontact = () => {
    return (
        <div>
            <p>
                Address :
                Farmstrasse

                64546 Mörfelden-Walldorf
            </p>
            <p>
                Instagram :
                nv_nagelstudio
            </p>
            Email :
            nvnagelstudio@gmail.com
            <p>
                Telephone :
                Handy:0162 9609309
            </p>

            <p>
                Website :
                nvnagelstudio.de
            </p>

            <div>
                <h5>
                    Öffnungszeiten :
                    Mo. -Fr.: 10-19 Uhr

                </h5>
                <h5>
                    Sa. : Termin nach vereinbarung

                </h5>

            </div>










        </div>
    );
};
