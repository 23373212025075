import React from 'react';
import { useCart } from 'react-use-cart';


const CardItem = (props) => {
    const { image_path, name, price, description } = props.item
    const { addItem } = useCart()




    return (
        <div className='col-12 col-md-6 col-lg-3 mb-3'>
            <div className="card h-100 shadow">
                <img src={image_path} className="card-img-top h-100" alt="..." />
                {/* <img src={`https://nvnageldb.de/storage/sliders/${image}`} className="card-img-top h-100" alt="..." /> */}
                <div className="card-body">
                    <h5 className="card-title text-center">{name}</h5>
                    <p className="card-text">{description}</p>
                    <div className='d-flex justify-content-between'>
                        <button className='btn btn-primary'
                            onClick={() => addItem(props.item)}
                        >add to Cart </button>
                        <h5 className="d-flex m-0 card-title fst-italic align-items-center">{price} €</h5>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default CardItem;