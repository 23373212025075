import React from 'react';
import { FiPhone, FiInstagram, FiMapPin, FiClock } from "react-icons/fi";
import { TfiWorld } from "react-icons/tfi";
import './Footer.css'

const Footer = () => {
    return (
        <div class="container-fluid bg-primary  ">
            <div className='container p-2'>
                <div class="row text-center">
                    <div class="col-sm-12 col-md-4 justify-content-center">

                        <div className='text-white'><FiMapPin />  Am Walldorf Bahnhof</div>
                        <div className='text-white'>Farmstraße</div>
                        <div className='text-white'>64546 Mörfelden-Walldorf</div>

                    </div>
                    <hr className='clearfix w-100 d-md-none' />
                    <div class="col-sm-12 col-md-4">
                        <div className='text-white'><FiClock />  ÖFFNUNGSZEITEN</div>
                        <div className='text-white'>Mo.-Fr. : 10:00 - 19:00 Uhr</div>
                        <div className='text-white'>Sa.:Termin nach vereinbarung</div>
                    </div>
                    <hr className='clearfix w-100 d-md-none' />

                    <div class="col-sm-12 col-md-4">

                        <div><a className='text-white ms-2 me-2' href='tel:01629609309' style={{ textDecoration: 'none' }}><FiPhone />: 0162 9609309</a></div>

                        <div className='text-white'><FiInstagram /> nv_nagelstudio</div>
                        <div className='text-white'><TfiWorld /> nvnagelstudio.de</div>

                    </div>
                    <hr className='clearfix w-100 d-md-none' />

                </div>
                <div class="row">
                    <div className='text-center fst-italic'> <small className='fw-bold'>&copy; 2024 Copyright: </small>vtp5330@gmail.com</div>

                </div>
            </div>
        </div>
    )


};

export default Footer;