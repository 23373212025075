import React from 'react';
import { useCart } from 'react-use-cart';
import useFetch from '../useFetch';

export const Cart = () => {
    const { data } = useFetch('https://www.nvnageldb.de/public/api/home')

    const {
        isEmpty,
        totalUniqueItems,
        items,
        totalItems,
        cartTotal,
        updateItemQuantity,
        removeItem,
        emptyCart
    } = useCart()
    if (isEmpty) return <h1 className='text-center'>Your cart is empty</h1>
    return (
        <section className='py-4 container'>
            <div className='row justify-content-center'>
                <div className='col-12'>
                    <h2 >Cart ({totalUniqueItems}) total items: ({totalItems})</h2>
                    <table className='table table-light m-0 table-hover'>
                        <tbody>
                            {items.map((item, index) => {
                                return (<tr key={index}>
                                    <td><img src={item.image_path} style={{ height: '6rem', width: '6rem' }} /></td>
                                    <td>{item.name} {item.description}</td>
                                    <td>{item.price} €</td>
                                    <td>Quantity ({item.quantity})</td>
                                    <td>
                                        <button
                                            className='btn btn-info ms-2'
                                            onClick={() => updateItemQuantity(item.id, item.quantity - 1)}
                                        >-</button>
                                        <button className='btn btn-info ms-2'
                                            onClick={() => updateItemQuantity(item.id, item.quantity + 1)}
                                        >+</button>
                                        <button className='btn btn-danger ms-2'
                                            onClick={() => removeItem(item.id)}
                                        >Remove</button>
                                    </td>
                                </tr>)
                            })}
                        </tbody>
                    </table>
                </div>
                <div className='col-auto ms-auto'>
                    <h3>Total Price : {cartTotal > 0 ? <>{cartTotal}</> : 0} €</h3>
                </div>
                <div className='col-auto'>
                    <button className='btn btn-danger m-4'
                        onClick={() => emptyCart()}
                    >Clear Cart</button>
                    <button className='btn btn-primary m-4'

                    >Buy Now</button>
                </div>
            </div>
        </section>
    );
};

