import React from 'react';
import CardItem from './CardItem';
import useFetch from '../useFetch';

export const Shellack = () => {
    const { data } = useFetch('https://www.nvnageldb.de/public/api/shellack')


    return (
        <>

            <div className='py-3 container' style={{ flex: '1' }}>
                <div className='row'>
                    {data.map((nvdata, index) => {
                        return (
                            <CardItem key={index}
                                item={nvdata}
                                image={nvdata.image}
                                id={nvdata.id}
                                name={nvdata.name}
                                description={nvdata.description}
                                price={nvdata.price}

                            />
                        )
                    })}
                </div>

            </div>

        </>
    );
};

