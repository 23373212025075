import { useEffect, useState } from 'react';
import axios from 'axios';

const useFetch = (url) => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)
        axios.get(url)
            .then(res => setData(res.data.data))
            .catch(err => console.log(err))
            .finally(() => setLoading(false))
    }, [url])


    return { data, loading }
};

export default useFetch;