import React from 'react';
import CardItem from './CardItem';
import useFetch from '../useFetch';
import Slider from '../Slider';

export const Home = () => {
    const { data } = useFetch('https://www.nvnageldb.de/public/api/home')


    return (
        <>
            {/* <div>{JSON.stringify(data)}</div> */}
            <Slider />
            <div className='py-3 container' style={{ flex: '1' }}>
                <div className='row'>
                    {data.map((nvdata, index) => {
                        return (
                            <CardItem key={index}
                                item={nvdata}
                                image_path={nvdata.image_path}
                                /* image={nvdata.image} */
                                id={nvdata.id}
                                name={nvdata.name}
                                description={nvdata.description}
                                price={nvdata.price}

                            />
                        )
                    })}
                </div>

            </div>

        </>
    );
};

